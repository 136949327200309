import {
  SET_MENU_DISPLAY,
  SET_EVENT_NOTIFICATION_DETAILS,
  SET_COUNTRY_DIALOG,
  SET_ALERT_DIALOG,
  SET_SUCCESS_DIALOG,
} from "../constants/other";

export const SetMenuDisplay = (values) => async (dispatch) => {
  dispatch({ type: SET_MENU_DISPLAY, payload: values });
};

export const SetEventNotificationDetails = (values) => async (dispatch) => {
  dispatch({ type: SET_EVENT_NOTIFICATION_DETAILS, payload: values });
};

export const SetCountryDialog = (values) => async (dispatch) => {
  dispatch({ type: SET_COUNTRY_DIALOG, payload: values });
};

export const SetAlertDialog = (values) => async (dispatch) => {
  dispatch({ type: SET_ALERT_DIALOG, payload: values });
};

export const SetSuccessDialog = (values) => async (dispatch) => {
  dispatch({ type: SET_SUCCESS_DIALOG, payload: values });
};
