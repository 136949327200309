export function useFreePlanCheck() {
  const userPlanDetails =
    sessionStorage.getItem("userPlanDetails") &&
    JSON.parse(sessionStorage.getItem("userPlanDetails"));

  return (
    userPlanDetails &&
    Object.keys(userPlanDetails).length > 0 &&
    userPlanDetails.planTitle &&
    userPlanDetails.planTitle === "Free Plan"
  );
}
