import {
  SET_MENU_DISPLAY,
  SET_EVENT_NOTIFICATION_DETAILS,
  SET_COUNTRY_DIALOG,
  SET_ALERT_DIALOG,
  SET_SUCCESS_DIALOG,
} from "../constants/other";

export function otherReducer(state = {}, action) {
  switch (action.type) {
    case SET_MENU_DISPLAY:
      return {
        ...state,
        setDisplay: action.payload,
      };
    case SET_EVENT_NOTIFICATION_DETAILS:
      return {
        ...state,
        eventNotificationDetailsData: action.payload,
      };
    case SET_COUNTRY_DIALOG:
      return {
        ...state,
        countryDialogData: action.payload,
      };
    case SET_ALERT_DIALOG:
      return {
        ...state,
        alertDialogData: action.payload,
      };
    case SET_SUCCESS_DIALOG:
      return {
        ...state,
        successDialogData: action.payload,
      };
    default:
      return state;
  }
}
