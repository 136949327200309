import {
  VERIFY_BETACODE_REQUEST,
  VERIFY_BETACODE_SUCCESS,
  VERIFY_BETACODE_FAILURE,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  PAYMENT_DETAILS,
  PAYMENT_INDENT,
  RESET,
  GET_ALL_SUBSCRIPTION_PLAN_REQUEST,
  GET_ALL_SUBSCRIPTION_PLAN_SUCCESS,
  GET_ALL_SUBSCRIPTION_PLAN_FAILURE,
  GET_ACTIVE_PLAN_REQUEST,
  GET_ACTIVE_PLAN_SUCCESS,
  GET_ACTIVE_PLAN_FAILURE,
  GRANT_CREDIT_REQUEST,
  GRANT_CREDIT_SUCCESS,
  GRANT_CREDIT_FAILURE,
  GET_ACTIVE_PLAN_BY_TEAM_ID_REQUEST,
  GET_ACTIVE_PLAN_BY_TEAM_ID_SUCCESS,
  GET_ACTIVE_PLAN_BY_TEAM_ID_FAILURE,
} from "../constants/subscription";
import axios from "axios";

export const VerifyBetaCode = (values) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_BETACODE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/UserSubscription/betaCodeCheck`,
      values
    );
    dispatch({
      type: VERIFY_BETACODE_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: VERIFY_BETACODE_FAILURE });
  }
};

export const CreateSubScription = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_SUBSCRIPTION_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/UserSubscription/createUserSubscription`,
      values
    );
    dispatch({
      type: CREATE_SUBSCRIPTION_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: CREATE_SUBSCRIPTION_FAILURE });
  }
};

export const SetPaymentIndent = (values) => async (dispatch) => {
  dispatch({ type: PAYMENT_INDENT, payload: values });
};

export const SetPaymentDetails = (values) => async (dispatch) => {
  dispatch({ type: PAYMENT_DETAILS, payload: values });
};

export const ResetSubscriptionState = () => async (dispatch) => {
  dispatch({ type: RESET });
};

export const GetAllSubScriptionPlans = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SUBSCRIPTION_PLAN_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/plan/plansList`,
      values
    );
    dispatch({
      type: GET_ALL_SUBSCRIPTION_PLAN_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_SUBSCRIPTION_PLAN_FAILURE });
  }
};

// Old API
// export const GetAllSubScriptionPlans = (values) => async (dispatch) => {
//   try {
//     dispatch({ type: GET_ALL_SUBSCRIPTION_PLAN_REQUEST });
//     const resData = await axios.post(
//       `${process.env.REACT_APP_API_URL}/api/plan/getAllPlanData`,
//       values
//     );
//     dispatch({
//       type: GET_ALL_SUBSCRIPTION_PLAN_SUCCESS,
//       payload: resData.data,
//     });
//   } catch (err) {
//     dispatch({ type: GET_ALL_SUBSCRIPTION_PLAN_FAILURE });
//   }
// };

export const GetActivePlan = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ACTIVE_PLAN_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/stripe/subscription-details`,
      values
    );
    dispatch({
      type: GET_ACTIVE_PLAN_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ACTIVE_PLAN_FAILURE });
  }
};

export const GetActivePlanOld = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ACTIVE_PLAN_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/UserSubscription/getSubscriptionData`,
      values
    );
    dispatch({
      type: GET_ACTIVE_PLAN_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ACTIVE_PLAN_FAILURE });
  }
};

export const GetActivePlanByTeamId = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ACTIVE_PLAN_BY_TEAM_ID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/UserSubscription/getSubscriptionDataByTeamId`,
      values
    );
    dispatch({
      type: GET_ACTIVE_PLAN_BY_TEAM_ID_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ACTIVE_PLAN_BY_TEAM_ID_FAILURE });
  }
};

export const GrantCreditToTeam = (values) => async (dispatch) => {
  try {
    dispatch({ type: GRANT_CREDIT_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/UserSubscription/UserSubscriptionTeamCredit`,
      values
    );
    dispatch({
      type: GRANT_CREDIT_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GRANT_CREDIT_FAILURE });
  }
};
