import React from "react";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";

export default function MessageSuccessAlert() {
  const toast = React.useRef(null);
  const { successDialogData } = useSelector((state) => state.others);

  React.useEffect(() => {
    if (
      successDialogData &&
      Object.keys(successDialogData).length > 0 &&
      successDialogData.vMessage &&
      successDialogData.eStatus
    ) {
      toast.current.show({
        severity: "success",
        summary: successDialogData.vMessage,
        life: 3000,
      });
    }
  }, [successDialogData]);

  return (
    <>
      <Toast ref={toast} />
    </>
  );
}
