import React from "react";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";

export default function MessageWarnAlert() {
  const toast = React.useRef(null);
  const { alertDialogData } = useSelector((state) => state.others);

  React.useEffect(() => {
    if (
      alertDialogData &&
      Object.keys(alertDialogData).length > 0 &&
      alertDialogData.vMessage &&
      alertDialogData.eStatus
    ) {
      toast.current.show({
        severity: "warn",
        summary: alertDialogData.vMessage,
        life: 3000,
      });
    }
  }, [alertDialogData]);

  return (
    <>
      <Toast ref={toast} />
    </>
  );
}
