import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";

function FirebaseLogEvents(event_name, description) {
  // console.log(event_name, description);

  return logEvent(analytics, event_name, {
    event_description: description,
    // debug_mode: true,
  });
}

export default FirebaseLogEvents;
