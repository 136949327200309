export function useFeatureAccess(vCategory, vFeatureName) {
  const userPlanDetails =
    sessionStorage.getItem("userPlanDetails") &&
    JSON.parse(sessionStorage.getItem("userPlanDetails"));

  const categoriesLengthCheck =
    userPlanDetails &&
    Object.keys(userPlanDetails).length > 0 &&
    userPlanDetails.categories &&
    userPlanDetails.categories.length > 0;

  const categories = categoriesLengthCheck && userPlanDetails.categories;

  if (categoriesLengthCheck && categories) {
    const category = categories.find(
      (i) => i.vCategory && i.vCategory === vCategory
    );
    if (category && Object.keys(category).length > 0) {
      if (category.features && category.features.length > 0) {
        const feature = category.features.find(
          (i) => i.vFeatureName && i.vFeatureName === vFeatureName
        );
        if (feature && Object.keys(feature).length > 0) {
          return feature;
        } else {
          return {};
        }
      }
    }
  }
}
